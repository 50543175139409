import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withSnackbar } from 'notistack'
import { Component } from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'

class BulkMessage extends Component {
    state = {
        message: ''
    }

    handleSubmit = () => {
        this.props.socket.send(
            JSON.stringify({
                message: this.state.message,
                me_id: JSON.parse(localStorage.getItem('user')).id,
                action: 'bulk_message',
                delivery_type: 'sent',
                full_name: JSON.parse(localStorage.getItem('user')).full_name
            })
        )
        this.props.toggleTab('currentUserId', null)
        this.props.toggleTab('showMessage', false)
        this.props.toggleTab('messages',[])
        this.props.toggleTab('userDetail','')
        this.props.getChatUsersList();
        this.props.toggleState('bulkMessage');
        this.props.enqueueSnackbar('Bulk Message Sent Successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }
        })
        // window.location.reload()

    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    render() {
        const { open, toggleState, studentData, type } = this.props
        return (
            <Dialog PaperProps={{ style: { width: '800px', minHeight: '300px', height: 'auto' } }}
                open={open} onClose={() => toggleState('bulkMessage')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        Send Bulk Messages
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('bulkMessage')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        <>
                            <FormGroup>
                                <Label>Message</Label>
                                <Input
                                    type={'textarea'} name={'message'}
                                    value={this.state.message}
                                    onChange={this.handleInputChange}
                                    rows={5}
                                    placeholder='Enter the message...'>
                                </Input>
                            </FormGroup>

                        </>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('bulkMessage')}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button' onClick={() => this.handleSubmit()}>
                        Submit
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(BulkMessage)
