/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import { Spinner, Modal, Button } from "reactstrap";
import Avatar from "../../assets/img/dummyUser.png";
import MaterialTable from 'material-table'
import './studentDetails.css'
import moment from 'moment'
const Services = require('../../RemoteServices/RemoteServices.js');


const ChatStudentProfilePage = (props) => {

    let [userDetails, setUserDetails] = useState({})
    let [referralListModal, setReferralListModal] = useState(false)
    let [followListModal, setFollowListModal] = useState(false)
    let [followTableTitle, setFollowTableTitle] = useState('')
    let [followList, setFollowList] = useState([])
    let [pageLoading, setPageLoading] = useState(true)
    let [detailResponse, setDetailResponse] = useState([])
    let { userId } = props

    const toggleReferralModal = () => {
        setReferralListModal(!referralListModal)
    }

    const toggleFollowModal = () => {
        setFollowListModal(!followListModal)
    }

    useEffect(() => {
        getUserDetails()
    }, [])


    const getUserDetails = () => {
        Services.getStudentDetailsNew(userId).then((response) => {
            setUserDetails(response.results[0])
            setDetailResponse(response.results)
            setPageLoading(false)

        })
    }

    const getFollowers = () => {
        Services.getUserFollowers(userId).then((response) => {
            console.log(response.results)
            setFollowList(response.results)
        })
    }

    const getFollowing = () => {
        Services.getUserFollowing(userId).then((response) => {
            setFollowList(response.results)

        })
    }


    const handleGetFollowers = () => {
        getFollowers()
        toggleFollowModal()
        setFollowTableTitle('Followers')
    }

    const handleGetFollowing = () => {
        getFollowing()
        toggleFollowModal()
        setFollowTableTitle('Following')
    }

    console.log('detail response here', detailResponse)

    let user = userDetails?.user
    let data = userDetails


    if (pageLoading === true || detailResponse.length === 0) {
        return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '20px' }}> <Spinner /> </div>)
    }

    return (
        <div className="studentDetailsTopContainer" >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', gap: 50 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h2 style={{ color: 'white', fontWeight: 'bold' }}>{user.full_name} </h2>
                    <a href={user.profile_picture} target="_blank" style={{ position: 'relative', display: 'inline-block', width: 100, height: 100 }}> {/* Adjust width and height as needed */}
                        <img
                            className="studentDetailImage"
                            alt="student avatar"
                            src={user.profile_picture ? user.profile_picture : Avatar}
                            style={{
                                width: '105px',
                                height: '105px',
                                objectFit: 'fill',
                                borderRadius: '50%'
                            }}
                        />
                        <span
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                width: 16,
                                height: 16,
                                borderRadius: '50%',
                                backgroundColor: user.is_online ? 'green' : 'lightgrey',
                                border: '2px solid white'
                            }}
                        />
                    </a>
                </div>
                <div>
                    <div style={{ backgroundColor: 'indigo' }}>
                        <div className="userIdInfoDiv" style={{ color: 'white' }}>
                            <span style={{color:'white'}}> User ID - {data.new_id}</span>
                            {user?.is_online ? ''
                         :
                            <span style={{color:'white',fontSize:14}}>Offline: {moment(user?.last_online).fromNow()}</span>
                        }
                            {/* <span> {data.is_paid ? "(PREMIUM USER)" : "(BASIC USER)"}</span> */}
                        </div>
                        <br />

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                        <ul style={{ gap: 10 }}>
                            <li> <i className="far fa-calendar"></i> Date of Birth: <span> {user.dob || 'N/A'} </span> </li>
                            <br />

                            <li> <i className="fas fa-venus-mars"></i> Gender : <span> {user.gender || 'N/A'} </span> </li>
                            <br />

                            <li> <i className="far fa-calendar-plus"></i> Joined : <span> {moment(user.created_at).format('MM/DD/YYYY') || 'N/A'} </span> </li>
                            <br />

                            <li> <i className="fas fa-home"></i> From : <span> {user.address || 'N/A'} </span> </li>
                            <br />

                            <li> <i className="fas fa-user-friends"></i> Referred By: <span> {user.referred_by || 'N/A'} </span> </li>
                            <br />


                        </ul>
                        <ul style={{ gap: 10 }}>
                            <li> <i class="fas fa-phone-square-alt"></i> Phone : <span> {user.phone_number || 'N/A'} </span> </li>
                            <br />

                            <li> <i class="fas fa-receipt"></i> Roll Number : <span> {user.id || 'N/A'} </span> </li>
                            <br />

                            <li> <i class="fas fa-envelope"></i> Email: <span> {user.email || 'N/A'}
                                &nbsp;{user?.email_verified && <i style={{ color: 'white', fontSize: 18 }} className={' fa fa-check-circle'}></i>}
                            </span> </li>
                            <br />

                            <li> <i class="fab fa-centercode"></i> Referral Code : <span> {user.referral_code || 'N/A'} </span> </li>
                            <br />

                            <li> <i class="fas fa-id-card-alt"></i> Referred Count : <span> {user.referred_count || 'N/A'} </span> </li>
                            <br />


                        </ul>
                        <ul style={{ gap: 10 }}>
                            <li> <i class="fas fa-book"></i> Payment Status : <span> {data?.is_paid ? 'Paid' : 'Unpaid'} </span> </li>
                            <br />

                            <li> <i class="fas fa-book"></i> Current Course : <span> {data?.courses?.[0]?.course_name || 'N/A'} </span> </li>
                            <br />

                            <li> <i class="fas fa-book"></i> Current Lesson : <span> {data?.current_lesson !== null ? data.current_lesson.title : 'NA'} </span> </li>
                            <br />
                            <li> <i class="fas fa-id-card-alt"></i> Referred By  : <span> {data.referred_by?.full_name || 'N/A'} </span> </li>
                            <br/>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatStudentProfilePage