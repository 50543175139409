/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import { Spinner, Modal, Button } from "reactstrap";
import Avatar from "../../assets/img/dummyUser.png";
import MaterialTable from 'material-table'
import './studentDetails.css'
import moment from 'moment'
const Services = require('../../RemoteServices/RemoteServices.js');


const UserProfilePage = (props) => {

    let [userDetails, setUserDetails] = useState({})
    let [referralListModal, setReferralListModal] = useState(false)
    let [followListModal, setFollowListModal] = useState(false)
    let [followTableTitle, setFollowTableTitle] = useState('')
    let [followList, setFollowList] = useState([])
    let [pageLoading, setPageLoading] = useState(true)
    let [detailResponse, setDetailResponse] = useState([])
    let { userId } = props


    console.log('asdfasdfasf',props)

    const toggleReferralModal = () => {
        setReferralListModal(!referralListModal)
    }

    const toggleFollowModal = () => {
        setFollowListModal(!followListModal)
    }

    useEffect(() => {
        getUserDetails()
    }, [])


    const getUserDetails = () => {
        Services.getStudentDetailsNew(userId).then((response) => {
            setUserDetails(response.results[0])
            setDetailResponse(response.results)
            setPageLoading(false)

        })
    }

    const getFollowers = () => {
        Services.getUserFollowers(userId).then((response) => {
            console.log(response.results)
            setFollowList(response.results)
        })
    }

    const getFollowing = () => {
        Services.getUserFollowing(userId).then((response) => {
            setFollowList(response.results)

        })
    }


    const handleGetFollowers = () => {
        getFollowers()
        toggleFollowModal()
        setFollowTableTitle('Followers')
    }

    const handleGetFollowing = () => {
        getFollowing()
        toggleFollowModal()
        setFollowTableTitle('Following')
    }

    console.log('detail response here', detailResponse)

    let user = userDetails?.user
    let data = userDetails


    if (pageLoading === true || detailResponse.length === 0) {
        return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '20px' }}> <Spinner /> </div>)
    }

    return (
        <div className="studentDetailsTopContainer">
            <div className="studentDetailsImageContainer">
                <span>{user.full_name}'s Profile </span>
                <a href={user.profile_picture} target="_blank" style={{ position: 'relative', display: 'inline-block', width: 100, height: 100 }}> {/* Adjust width and height as needed */}
                    <img
                        className="studentDetailImage"
                        alt="student avatar"
                        src={user.profile_picture ? user.profile_picture : Avatar}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'fill',
                            borderRadius: '50%'
                        }}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            backgroundColor: user.is_online ? 'green' : 'lightgrey',
                            border: '2px solid white'
                        }}
                    />
                </a>
                <br />
                <button className="referralListButton" onClick={toggleReferralModal}> Referral List</button>
            </div>
            <div className="studentDetailsTopInfoContainer">
                <div className="studentDetailsInfoTopButtons">
                    <div className="userIdInfoDiv">
                        <span> User ID - {data.new_id}</span>
                        {user?.is_online ?'' :
                            <span>Offline: {moment(user?.last_online).fromNow()}</span>
                        }
                        <span style={{color:'white'}}> {data.is_paid ? "(PREMIUM USER)" : "(BASIC USER)"}</span>
                    </div>
                    <div className="studentTopButtonsDiv">
                        <button onClick={handleGetFollowers}>Followers: {user.followers_count||'N/A'}</button>
                        <button onClick={handleGetFollowing}>Following: {user.following_count||'N/A'}</button>
                        <button>Post Count: {user.post_count||'N/A'}</button>
                        <button>Post Likes: {user.posts_like_count||'N/A'} </button>
                    </div>

                </div>
                <div className="studentDetailsTopInfoInnerContainer1">
                    <ul>
                        <li> <i className="far fa-calendar"></i> Date of Birth: <span> {user.dob||'N/A'} </span> </li>
                        <li> <i className="fas fa-venus-mars"></i> Gender : <span> {user.gender||'N/A'} </span> </li>
                        <li> <i className="far fa-calendar-plus"></i> Joined : <span> {moment(user.created_at).format('MM/DD/YYYY')} </span> </li>
                        <li> <i className="fas fa-home"></i> From : <span> {user?.address||'N/A'} </span> </li>
                        <li> <i className="fas fa-user-friends"></i> Referred By: <span> {data.referred_by?.full_name||'N/A'} </span> </li>

                    </ul>
                    <ul>


                        <li> <i class="fas fa-phone-square-alt"></i> Phone : <span> {user.phone_number||'N/A'} </span> </li>
                        <li> <i class="fas fa-receipt"></i> Roll Number : <span> {user.id||'N/A'} </span> </li>
                        <li> <i class="fas fa-envelope"></i> Email: <span> {user.email||'N/A'}
                            &nbsp;{user?.email_verified && <i style={{ color: 'white', fontSize: 18 }} className={' fa fa-check-circle'}></i>}
                        </span> </li>
                        <li> <i class="fab fa-centercode"></i> Referral Code : <span> {user?.referral_code||'N/A'} </span> </li>
                        <li> <i class="fas fa-id-card-alt"></i> Referred Count : <span> {user?.referred_count||'N/A'} </span> </li>

                    </ul>
                    <ul>

                        <li> <i class="fas fa-book"></i> Current Course : <span> {data?.courses[0]?.course_name||'N/A'} </span> </li>
                        <li> <i class="fas fa-book"></i> Current Lesson : <span> {data?.current_lesson !== null ? data.current_lesson.title : 'NA'} </span> </li>
                        <li> <i class="fas fa-money-check-alt"></i> Remaining Balance : <span> {user.remaining_balance||'N/A'} </span> </li>
                        <li> <i class="fas fa-money-check-alt"></i> Delivered amount : <span> {data.delivered_amount||'N/A'} </span> </li>
                        <li> <i class="fas fa-money-check-alt"></i> Earned Amount : <span> {data.paid_referrals * 1000} </span> </li>


                    </ul>
                </div>



            </div>

            <Modal
                className="modal-dialog-centered"
                isOpen={referralListModal}
                toggle={toggleReferralModal}
            >
                <div className="modal-header">
                    {/* <h4 className="modal-title" id="modal-title-default"> 
                  Referrals List
                </h4> */}
                    <Button
                        size="sm"
                        className="ml-auto"
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleReferralModal}
                    >
                        Close
                    </Button>
                </div>
                {/* <div className="modal-body"> */}
                <MaterialTable
                    columns={[
                        { title: 'Full name', field: 'full_name' },
                        { title: 'Email', field: 'email' },
                    ]}
                    data={user.referrals}
                    title="Referrals List"
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#6772E5',
                            color: '#FFF'
                        }
                    }}
                />
                {/* </div> */}

            </Modal>

            <Modal
                className="modal-dialog-centered"
                isOpen={followListModal}
                toggle={toggleFollowModal}
            >
                <div className="modal-header">
                    {/* <h4 className="modal-title" id="modal-title-default"> 
                  Referrals List
                </h4> */}
                    <Button
                        size="sm"
                        className="ml-auto"
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleFollowModal}
                    >
                        Close
                    </Button>
                </div>
                {/* <div className="modal-body"> */}
                <MaterialTable
                    columns={[
                        { title: 'Avatar', field: 'imageUrl', render: (rowData) => <img alt="User Avatar" src={followTableTitle === 'Followers' ? rowData?.followers?.profile_picture : rowData?.following?.profile_picture} style={{ width: 40, borderRadius: '50%' }} /> },
                        { title: 'Full name', field: followTableTitle === 'Followers' ? 'followers.full_name' : 'following.full_name' },

                    ]}
                    data={followList}
                    title={followTableTitle}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#6772E5',
                            color: '#FFF'
                        }
                    }}
                />
                {/* </div> */}

            </Modal>
        </div>
    )
}

export default UserProfilePage